<template>
  <b-container fluid="xxl">
    <b-alert show variant="dark">This view is not part of the prototype phase!</b-alert>
  </b-container>
</template>

<script>
export default {
  name: 'ManageTags'
}
</script>
